import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/investments_layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage } from "gatsby-plugin-image"
import Carousel from "react-bootstrap/Carousel"
import Meta from "../components/meta"
import ImageCard from "../components/image_card"


const About = ({ data }) => (
  <Layout>
    <Meta title="threesixty Investments Ltd Founders" description="Learn more about our team of highly experienced directors." />
    <Container>
      <Row>
        <Col>
          <h1 className="display-5 mb-4">Founders</h1>
        </Col>
      </Row>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <section>
          <Row className="mb-4 team-member d-none d-sm-flex">
            <Col>
              <div className="card">
                <GatsbyImage className="card-img" width="500" image={node.frontmatter.bigImage.childImageSharp.gatsbyImageData} />
              </div>
            </Col>
            <Col>
              <h2 className="fw-normal">{node.frontmatter.name}</h2>
              <h3>{node.frontmatter.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
            </Col>
          </Row>
          <Row className="mb-4 team-member d-block d-sm-none">
            <Col>
              <Carousel controls={true} interval={null}>
                <Carousel.Item>
                  <div className="card">
                    <GatsbyImage className="card-img" width="500" image={node.frontmatter.bigImage.childImageSharp.gatsbyImageData} />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="card">
                    <GatsbyImage className="card-img" width="500" image={node.frontmatter.sideImage.childImageSharp.gatsbyImageData} />
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col>
              <h2 className="fw-normal">{node.frontmatter.name}</h2>
              <h3>{node.frontmatter.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
            </Col>
          </Row>
        </section>
      ))}
      <br />
      <hr />
      <Row xs={6} className="gx-4">
        <Col>
          <ImageCard
            image={data.peterSidePortraitImg}
            alt="Peter Raybould"
          />
        </Col>
        <Col>
          <ImageCard
            image={data.peterPortraitImg}
            alt="Peter Raybould"
          />
        </Col>
        <Col>
          <ImageCard
            image={data.simonSidePortraitImg}
            alt="Simon Ling"
          />
        </Col>
        <Col>
          <ImageCard
            image={data.simonPortraitImg}
            alt="Simon Ling"
          />
        </Col>
        <Col>
          <ImageCard
            image={data.johnSidePortraitImg}
            alt="John Whitfield"
          />
        </Col>
        <Col>
          <ImageCard
            image={data.johnPortraitImg}
            alt="John Whitfield"
          />
        </Col>
      </Row>
      <hr />
    </Container >
  </Layout >
)
export default About;

export const aboutImage = graphql`
  fragment aboutImage on File {
    childImageSharp {
      gatsbyImageData(width: 400, height: 250, quality: 90, layout: CONSTRAINED, transformOptions: {cropFocus: NORTH} )
    }
  }

  fragment aboutThumbnail on File {
    childImageSharp {
      gatsbyImageData(width: 500, height: 500, quality: 90, layout: CONSTRAINED, transformOptions: {cropFocus: NORTH})
    }
  }
`

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { type: { eq: "founders" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            name
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              ...aboutThumbnail
            }
            bigImage {
              ...aboutThumbnail
            }
            sideImage {
              ...aboutThumbnail
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    johnPortraitImg: file(relativePath: {eq: "john-portrait.jpg"}) {
      ...aboutImage
    }
    johnSidePortraitImg: file(relativePath: {eq: "john-side-portrait.jpg"}) {
      ...aboutImage
    }
    peterPortraitImg: file(relativePath: {eq: "peter-portrait-new-cropped.jpg"}) {
      ...aboutImage
    }
    peterSidePortraitImg: file(relativePath: {eq: "peter-side-portrait.jpg"}) {
      ...aboutImage
    }
    simonPortraitImg: file(relativePath: {eq: "simon-portrait.jpg"}) {
      ...aboutImage
    }
    simonSidePortraitImg: file(relativePath: {eq: "simon-side-portrait.jpg"}) {
      ...aboutImage
    }
  }
`

