import React from "react"
import Card from "react-bootstrap/Card"
import { GatsbyImage } from "gatsby-plugin-image"


function ImageCard(props) {
  return (
    <Card className="bg-dark text-white text-center d-flex">
      <GatsbyImage image={props.image.childImageSharp.gatsbyImageData} alt={props.alt} className="card-img" height="250" />
      <Card.ImgOverlay className="d-flex align-items-center">
        <Card.Body className="d-flex align-items-center justify-content-center">
          <Card.Title className="montserrat">{props.title}</Card.Title>
        </Card.Body>
      </Card.ImgOverlay>
    </Card>
  )
}

export default ImageCard
